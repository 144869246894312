import "../scss/main.scss";

(function ($) {

	$(window).scroll(function() {    
		var scroll = $(window).scrollTop();
		if (scroll >= 500) {
			$("header").addClass("scrollHeader");
		} else {
			$("header").removeClass("scrollHeader");
		}
	});

	$(document).ready(function () {
		$(".toggle-nav").click(function () {
			$(".menu-mobile").toggleClass("visible");
		});
	});

	$(document).ready(function () {
		$(".menu-item a").click(function () {
			$(".menu-mobile").toggleClass("visible");
		});
	});

	$(document).ready(function () {
		$(".trigger").click(function () {
			$(".modal").toggleClass("show-modal");
		});
	});
	$(document).ready(function () {
		$(".close-button").click(function () {
			$(".modal").toggleClass("show-modal");
		});
	});

	$(document).ready(function () {
		theme.init();
	});

	var theme = {
		init: function () {
			this.animationOnScroll();
		},

		animationOnScroll: function(){
			$(function() {
				AOS.init({disable: 'mobile'});
            });
		}
	}
})(jQuery)
